// https://bootswatch.com/vapor

&[data-converse-theme="cyberpunk"],
&[data-bs-theme="cyberpunk"] {
    $theme: 'vapor' !default;

    // Hex color values
    // ----------------
    --blue: #1ba2f6;
    --cyan: #32fbe2;
    --green: #3cf281;
    --indigo: #6610f2;
    --orange: #f1b633;
    --pink: #ea39b8;
    --purple: #6f42c1;
    --red: #e44c55;
    --teal: #3f81a2;
    --white: #efefef;
    --yellow: #ffc107;

    // Base variables
    // --------------
    --background-color: #1a0933;
    --background-color-lighter: #2C174C;
    --foreground-color: var(--white);

    // Bootstrap variables
    --primary-color: var(--purple) !important;
    --secondary-color: var(--indigo) !important;
    --success-color: var(--green);
    --danger-color: var(--red);
    --warning-color: var(--orange);
    --info-color: var(--yellow);
    --converse-highlight-color: var(--yellow);

    // Online status indicators
    --chat-status-away: var(--orange);
    --chat-status-busy: var(--red);
    --chat-status-offline: gray;
    --chat-status-online: var(--green);

    --controlbox-color: var(--purple);
    --disabled-color: gray;
    --error-color: var(--red);
    --focus-color: var(--secondary-color);
    --heading-color: var(--purple);
    --headlines-color: var(--pink);
    --link-color: var(--cyan);

    // The background when selecting text with your mouse
    --selection-color: black;

    // Used to indicate selected or open items in lists.
    --highlight-color: var(--purple);
    --highlight-color-hover: var(--background-color-lighter);

    // 1:1 Chat-related colors
    --chat-color: var(--green);
    --chat-header-fg-color: var(--chat-color);
    --chat-header-bg-color: var(--background-color);

    // MUC-related colors
    --muc-color: var(--orange);
    --muc-header-fg-color: var(--muc-color);
    --muc-header-bg-color: var(--background-color);

    // Derived colors
    // --------------
    --brand-heading-color: var(--cyan);
    --chat-color-hover: var(--chat-color);
    --danger-color-hover: var(--danger-color);
    --global-background-color: var(--background-color);
    --link-color-hover: var(--link-color);
    --muc-color-hover: var(--muc-color);
    --primary-color-hover: var(--purple);
    --secondary-color-hover: var(--secondary-color);
    --success-color-hover: var(--success);
    --warning-color-hover: var(--orange);

    #controlbox {
        .flyout {
            box-shadow:
                0 0 1rem 0rem rgba(234, 57, 184, 0.5) inset,
                0 0 2rem 0rem rgba(234, 57, 184, 0.2) inset,
                0 0 4rem 2rem rgba(234, 57, 184, 0.1) inset !important;
        }
    }

    .modal-header {
        .btn-close {
            background-color: var(--header-color);
        }
    }

    .message .separator-text {
        border-radius: 50px;
        box-shadow:
            0 0 0.25rem rgba(60, 242, 129, 0.8),
            0 0 1rem rgba(60, 242, 129, 0.2),
            0 0 4rem rgba(60, 242, 129, 0.1) !important;
    }

    .card {
        box-shadow:
            inset 0 0 0.5rem rgba(234, 57, 184, 0.8),
            inset 0 0 1rem rgba(234, 57, 184, 0.4),
            inset 0 0 2rem rgba(234, 57, 184, 0.3),
            inset 0 0 4rem rgba(234, 57, 184, 0.1) !important;
    }

    .chat-toolbar {
        box-shadow:
            0 0 0.5rem rgba(60, 242, 129, 0.4),
            0 0 2rem rgba(60, 242, 129, 0.2),
            0 0 4rem rgba(60, 242, 129, 0.1) !important;
    }

    .chat-head-chatbox {
        box-shadow:
            0 0 1rem 0rem rgba(60, 242, 129, 0.5) inset,
            0 0 2rem 0rem rgba(60, 242, 129, 0.4) inset,
            0 0 4rem 2rem rgba(60, 242, 129, 0.2) inset,
            0 0 1rem 0rem rgba(60, 242, 129, 0.4),
            0 0 2rem 0rem rgba(60, 242, 129, 0.2),
            0 0 4rem 0rem rgba(60, 242, 129, 0.1) !important;
    }

    .chatroom {
        .message .separator-text {
            border-radius: 50px;
            box-shadow:
                0 0 0.25rem rgba(255, 193, 7, 0.8),
                0 0 1rem rgba(255, 193, 7, 0.2),
                0 0 4rem rgba(255, 193, 7, 0.1) !important;
        }

        .chat-head-chatroom {
            box-shadow:
                0 0 1rem 0rem rgba(255, 193, 7, 0.5) inset,
                0 0 2rem 0rem rgba(255, 193, 7, 0.4) inset,
                0 0 4rem 2rem rgba(255, 193, 7, 0.2) inset,
                0 0 1rem 0rem rgba(255, 193, 7, 0.4),
                0 0 2rem 0rem rgba(255, 193, 7, 0.2),
                0 0 4rem 0rem rgba(255, 193, 7, 0.1) !important;
        }

        converse-muc-sidebar {
            box-shadow: 0 0 1rem 1rem rgba(255, 193, 7, 0.1) inset !important;
        }

        .chat-toolbar {
            box-shadow:
                0 0 0.5rem rgba(255, 193, 7, 0.4),
                0 0 2rem rgba(255, 193, 7, 0.2),
                0 0 4rem rgba(255, 193, 7, 0.1) !important;
        }
    }

    .moving-grid {
        position: absolute;
        top: 42%;
        left: 0;
        width: 100%;
        height: 70%;
        perspective: 1000px;
        &:before {
            content: '';
            position: absolute;
            top: 4rem;
            width: 100%;
            height: 0;
            box-shadow:
                0 0 1rem 0.5rem rgba(255, 255, 255, 0.5),
                0 0 2rem 0rem rgba(234, 57, 184, 0.3),
                0 0 4rem 2rem rgba(234, 57, 184, 0.1) !important;
        }
        .container {
            position: relative;
            width: 100%;
            height: 300%;
            border: 2px solid pink;
            box-sizing: border-box;
            transform-origin: top;
            transform: rotateX(57deg) translateY(10%);
            overflow: hidden;
            .static-lines {
                display: inline-block;
                width: 100%;
                height: 100%;
                .vert {
                    display: inline-block;
                    height: 100%;
                    width: 2px;
                    background-color: pink;
                    margin-inline-start: 4.5%;
                    box-shadow: 0 2px 21px pink;
                }
            }
            .moving-lines {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 300%;
                .horz {
                    display: inline-block;
                    width: 100%;
                    height: 3px;
                    margin-top: 4.8%;
                    background-color: pink;
                    box-shadow: 2px 0 21px pink;
                    animation: lines 15000ms linear infinite;
                }
            }
        }
    }

    @keyframes lines {
        from {
            transform: translateY(-65px);
        }
        to {
            transform: translateY(65px);
        }
    }
}
